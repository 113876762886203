import { device } from "../../../common/constants/device";
import styled, { keyframes } from "styled-components";

export const ContainerCarrousel = styled.section`
  max-width: 1400px;
  margin: auto;
  padding: 6rem 1rem;

  & .swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-right: 120px;
  }

  & .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    transition: all 0.8s ease;
    border-radius: 20px;
    flex: 0 0 auto; /* Ancho automático */
    &.swiper-slide-active {
      color: var(--font-color-dark);
      background: var(--main-bg-color, #0a060d);
      box-shadow: 0px 0px 50px 0px var(--Mystic-opacity-25);
      transform: translateY(0);
      width: 360px !important;
      flex: 0 0 360px; /* Ancho fijo de 360px para el slider activo */

      z-index: 2;
      &:hover {
        cursor: default;
      }
      & .slide-header {
        display: flex;
      }

      & .slide-body {
        display: block;
      }
    }
  }

  & .swiper-slide img {
    display: block;
    width: 100%;

    border-radius: 20px;
  }

  /* Estilo para el slide anterior al activo */ /* Estilo para el slide siguiente al activo */
  & .swiper-slide.swiper-slide-prev,
  & .swiper-slide.swiper-slide-next {
    transform: translateY(
      100px
    ) !important; /* Desplazamiento hacia abajo de 60px */
    // width: 250px !important;
    &:hover {
      cursor: pointer;
    }
  }

  /* Estilo para otros slides inactivos */
  &
    .swiper-slide:not(.swiper-slide-active):not(.swiper-slide-prev):not(
      .swiper-slide-next
    ) {
    transform: translateY(
      200px
    ) !important; /* Desplazamiento hacia abajo de 120px */
    // width: 214px !important;
    &:hover {
      cursor: pointer;
    }
  }
  @media ${device.desktop} {
    & .swiper-slide.swiper-slide-prev {
      width: auto;
    }

    & .swiper-slide.swiper-slide-next {
      width: auto;
    }

    &
      .swiper-slide:not(.swiper-slide-active):not(.swiper-slide-prev):not(
        .swiper-slide-next
      ) {
      width: auto;
    }
  }
  @media ${device.tabletL} {
    & .swiper {
      padding-right: 72px;
    }
  }
  @media ${device.mobileL} {
    & .swiper-slide {
      &.swiper-slide-active {
        max-width: 360px !important;
        z-index: 2;
        &:hover {
          cursor: default;
        }
        & .slide-header {
          display: flex;
        }

        & .slide-body {
          display: block;
        }
      }
    }
  }
  @media ${device.mobileM} {
    & .swiper-slide {
      &.swiper-slide-active {
        max-width: 320px !important;
      }
    }
  }
`;

export const SlideStyles = styled.section`
  position: relative;

  & > .slide-header {
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    z-index: 5;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px 15px;
    & > .back,
    & > .menu {
      cursor: pointer;
    }
  }

  & > .img-model {
    width: 100%;
    height: 500px;
    overflow: hidden;
    object-fit: cover;
  }

  & > .slide-body {
    padding: 20px 10px;
    display: none;
    & > .info {
      & > .user-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 1rem;
        color: var(--WhiteEndless, #f2f2f2);
        font-family: Mulish;
        font-size: 17px;
        font-weight: 400;

        & > .nickname {
          text-decoration-line: underline;
        }
        & > .users {
          display: flex;
          gap: 0.5rem;
          align-items: center;
        }
      }
      & > .price-info {
        color: var(--WhiteEndless, #f2f2f2);
        font-family: Mulish;
        font-size: 20px;
        font-weight: 700;
        display: flex;
        gap: 0.3rem;
        align-items: center;
        padding-bottom: 0.5rem;
        & > .currency {
          color: var(--WhiteEndless, #f2f2f2);
          font-size: 12px;
          font-weight: 400;
        }
      }
      & > .description {
        color: var(--WhiteEndless, #f2f2f2);
        font-family: Mulish;
        font-size: 15px;
        & > p {
          font-weight: 700;
          margin: 0;
        }
        & > ul {
          margin: 0 0 1rem;
          padding-left: 25px;
        }
      }
    }
  }

  @media ${device.mobileL} {
    & > .img-model {
      width: 100%;
      height: 400px;
      overflow: hidden;
      object-fit: cover;
    }
    & > .description {
      font-size: 12px;
    }
  }
`;
