"use client";

import { useTranslation } from "react-i18next";

import Button from "../../Button";

import { ContainerLanding } from "./styles";

export const Description = () => {
  const { t } = useTranslation();
  return (
    <ContainerLanding>
      <div className={`section-title `}>
        <p className="text1">{t("landingPage.welcome")}</p>
        <p className="text2">
          <span className="small">{t("landingPage.the")}</span>{" "}
          {t("landingPage.mansion")}
        </p>
        <p className="text3">
          <span className="small">{t("landingPage.of")}</span>{" "}
          {t("landingPage.sefty")}
        </p>
      </div>
      <div className="section-text">
        <p className="text-description">{t("landingPage.description")}</p>
        <p className="text-description">{t("landingPage.description2")}</p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "300px",
          margin: "auto",
          marginTop: "2rem",
        }}
      >
        <Button btnName={t("landingPage.btnBuy")} />
      </div>
    </ContainerLanding>
  );
};
