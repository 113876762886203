"use client";

import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import Button from "../../Button";

import { ContainerCarrousel, SlideStyles } from "./styles";

import {
  Back,
  Hearth,
  Logo,
  Menu,
  Model1,
  Model2,
  Model3,
  Model4,
  Model5,
  Model6,
  Model7,
  Monika,
} from "../img";

export const CarrouselModels = () => {
  const { t } = useTranslation();

  let dataModel = [
    {
      nickname: "@Kimberly",
      likes: "61K",
      price: 580,
      image: Model2,
    },
    {
      nickname: "@VivianaOlarteM",
      likes: "81K",
      price: 339,
      image: Model3,
    },
    {
      nickname: "@Monika",
      likes: "69K",
      price: 320,
      image: Monika,
    },
    {
      nickname: "@Sophia",
      likes: "62K",
      price: 480,
      image: Model4,
    },
    {
      nickname: "@Rangi69",
      likes: "300K",
      price: 305,
      image: Model5,
    },
    {
      nickname: "@AlejaHonney",
      likes: "81K",
      price: 280,
      image: Model6,
    },
    {
      nickname: "@JuanitaRoja",
      likes: "10K",
      price: 320,
      image: Model7,
    },
  ];
  const [swiperReady, setSwiperReady] = useState(false);
  const swiperRef = useRef(null);

  useEffect(() => {
    if (!swiperReady && swiperRef.current) {
      setSwiperReady(true);
    }
  }, [swiperReady]);

  const handleSlideClick = (index) => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(index);
    }
  };
  return (
    <ContainerCarrousel>
      <Swiper
        breakpoints={{
          300: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          540: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          1025: {
            slidesPerView: 5,
            spaceBetween: 20,
          },
        }}
        /*  grabCursor={true} */
        centeredSlides={true}
        slidesPerView={5}
        /* loop={true} */
        freeMode={true}
        initialSlide={2}
        spaceBetween={20}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        pagination={{ clickable: true }}
        modules={[Pagination /* Navigation */]}
        className="mySwiper"
      >
        {dataModel.map((model, idx) => {
          return (
            <SwiperSlide
              key={model.nickname}
              onClick={() => handleSlideClick(idx)}
            >
              <SlideStyles>
                <div className="slide-header">
                  <div className="logo">
                    <img src={Logo} alt="logo" />
                  </div>
                </div>
                <div className="img-model">
                  <img src={model.image} alt="model" />
                </div>
                <div className="slide-body">
                  <div className="info">
                    <div className="user-info">
                      <p className="nickname">{model.nickname}</p>
                      <p className="users">
                        <img src={Hearth} alt="likes" /> {model.likes}
                      </p>
                    </div>
                  </div>
                  <div className="btn">
                    <Button btnName={t("landingPage.btnBuy")} />
                  </div>
                </div>
              </SlideStyles>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </ContainerCarrousel>
  );
};
