//INTERNAL IMPORT
import Style from "./NavBar.module.css";
//import Lang from "@/components/Lang";

const NavBar = () => {
  return (
    <>
      <div className={Style.navbar}>
        <div className={Style.navbar_container}>
          <>
            <div className={Style.logo}>
              <img
                src="/images/logos/logo_horizontal_white.png"
                alt="Sefty"
                width={185}
                height={50}
                priority
              />
            </div>
            <div className={Style.logoMini}>
              <img
                src="/images/logos/logo_white.svg"
                alt="Sefty"
                width={80}
                height={50}
              />
            </div>
          </>
          <div className={Style.navbar_container_right}>
            {/* <div className={Style.buttons}>
              <Lang />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default NavBar;
