export const translationsEN = {
  heroVideoSection: {
    title: "LIVE",
    titleSpan: "the",
    titleSpan2: "LUST",
    titleSpan3: "in the",
    mansion: "mysterious",
    mystery: "mansion",
    text: "Get ready to discover a universe of pleasure, a place where excitement is always waiting for you behind every door",
    text2: "Are you ready to explore the mystery hidden in every corner?",
    button: "Start now",
  },
  landingPage: {
    welcome: "Welcome",
    the: "to the",
    mansion: "mansion",
    of: "of",
    lust: "lust",
    sefty: "Sefty",
    description:
      "A place where fun is reborn, where your emotions are unleashed and where you can be completely yourself.",
    description2:
      "Are you ready to discover corners full of pleasure, fun and confidence? Get ready to unleash your most intense emotions in exclusive spaces, that only you control.",
    description3:
      "Build your own mansion for pleasure, where every feature, accessory and detail will be chosen by you, creating totally unique and personalized experiences, with protected content that can be yours and you can even market.",
    description4:
      "Step into a realm of pleasure where each membership level is a gateway to more intense and exclusive experiences. Your entry into the royal hierarchy elevates you to new heights of delight, where every nook and cranny reveals more sensual secrets and absolute satisfaction. Join us and discover the keys that will unlock a world where pleasure has the highest hierarchy.",
    buy: "Buy &",
    customize: "customize",
    buyAnd: "buy &",
    btnBuy: "See you soon...",
    sell: "sell",
    pleasure: "The pleasure",
    elevated: "elevated to",
    art: "art",
    owner: "The owner of this NFT will receive:",
    text1: "1 month free subscription to my Sefty page",
    text2: "FREE 2 minute video of me dancing topless in a similar outfit.",
    text3: "2 Photos with the clothes that you like the most",
    explore: "Explore and discover",
    exploreDescription:
      "Explore your deepest desires and discover what we have prepared for you in this place of exclusivity and delight, where fantasies become reality. \n\n\n Here the fun never ends. ",
    create: "Create and customize",
    createDescription:
      "Customize every corner of your experience, with the freedom to be the director of your own story. Here your wish is our command and you have a variety of options and sensual details to create your own tale of passion and mystery.",
    enjoy: "Enjoy and value",
    enjoyDescription:
      "Here, every moment is an opportunity to appreciate the beauty of pleasure and emotion. Value the exclusivity we offer you and enjoy an experience that is specially designed for you.",
    sellAndWin: "Sell and earn with everything",
    sellAndWinDescription:
      "We give you the opportunity to explore spaces where your desires become currency. Selling and earning has never been so sensual and exciting. Here, you will be able to create and offer experiences that awaken passions and satisfy desires.",
    question: "Shall we go for the second one?",
    questionDescription:
      "No tenemos límites para la intensidad y el deseo, te invitamos a duplicar la pasión y la emoción en cada visita. Aquí, la diversión continúa con experiencias aún más provocativas, descubriendo un mundo de placer que se despliega ante ti.",
    tooltip0:
      "The user will have the freedom to browse the entire ecosystem without any restrictions.",
    tooltip1:
      "Exclusive events that you can enjoy remotely and securely, with unique and exciting experiences that only sefty has prepared especially for your enjoyment.  ",
    tooltip2: "You will be able to send personalized messages to models.",
    tooltip3: "Calls you'll be able to make to models.",
    tooltip4:
      "The user will enjoy the behind-the-scenes of how the model experiences the before, during and after a show.",
    tooltip5:
      "Benefit given to the user when purchasing props for content creation.",
    tooltip6:
      "Exclusive content from the most TOP models . These contents are only available to those who are part of our community.",
    tooltip7:
      "By purchasing the membership you will be able to redeem the value in coins and enjoy them by browsing the Sefty ecosystem.",
    twentyPerMonth: "20 per month",
    twentyDiscount: "20% discount",
    btnPionner: "Click and be a pioneer",
  },
  footer: {
    terms: "Terms & Conditions",
    privacy: "Privacy Policy",
    copyRight: "© 2023 Sefty.io All Rights Reserved.",
  },
};
