import Style from "./Button.module.css";

const Button = ({
  btnName,
  btnNameHover,
  animated,
  handleClick = () => {},
  icon,
  classStyle,
  simple,
  small,
  type = "button",
  white,
  disabled,
  colorSmall = "#999999",
}) => {
  return (
    <div className={Style.box + ` ${animated ? Style.animated_btn : ""}`}>
      <button
        type={type}
        disabled={disabled}
        className={` ${classStyle} ${simple ? Style.btnsimple : Style.button} ${
          small ? Style.btnsmall : Style.button
        } ${white ? Style.btnwhite : Style.button} ${
          btnNameHover ? Style.hover_action : ""
        }`}
        style={{
          border: `${small && `1px solid ${colorSmall}`}`,
          color: small ? colorSmall : "#fff",
        }}
        onClick={() => handleClick()}
      >
        {animated && (
          <>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </>
        )}
        {icon} <span className={Style.normal_txt}>{btnName}</span>{" "}
        <span className={Style.hover_txt}>{btnNameHover}</span>
      </button>
    </div>
  );
};

export default Button;
