"use client";

import React from "react";
import { useTranslation } from "react-i18next";

//INTERNAL IMPORT
import Style from "./HeroSection.module.css";

export const HeroSection = () => {
  const { t } = useTranslation();

  return (
    <div className={Style.heroSection}>
      <div className={Style.heroSection_box}>
        <div className={Style.heroSection_box_left}>
          <div className={Style.heroTitle}>
            <p className={Style.heroSection_box_left_title}>
              <span className={Style.title}>{t("heroVideoSection.title")}</span>
              <span>
                <span className={Style.title}>
                  {t("heroVideoSection.titleSpan")}&nbsp;
                </span>
                <span className={Style.title2}>
                  {t("heroVideoSection.titleSpan2")}&nbsp;
                </span>
                <span className={Style.title3}>
                  {t("heroVideoSection.titleSpan3")}
                </span>
              </span>
              <span className={Style.pleasant}>
                {t("heroVideoSection.mansion")}
              </span>
              <span className={Style.title4}>
                {t("heroVideoSection.mystery")}
              </span>
            </p>
          </div>
          <p className={Style.heroSection_box_left_text}>
            {t("heroVideoSection.text")}
          </p>
          <p className={Style.heroSection_box_left_text}>
            {t("heroVideoSection.text2")}
          </p>
          <div className={Style.heroSection_btn}>
            <button className={Style.heroSection_box_left_button}>
              {t("landingPage.btnBuy")}
            </button>
          </div>
        </div>
      </div>
      <div className={Style.heroVideo}>
        <video
          autoPlay
          muted
          loop
          width="400"
          src={"https://files.sefty.io/landing.mp4"}
        />
      </div>
    </div>
  );
};
