import { useTranslation } from "react-i18next";

import Style from "./Footer.module.css";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <div className={Style.footer} id="footer-admin">
      <div className={Style.footer_box}>
        <div className={Style.footer_box_left}>
          <img
            src="/images/logos/logo_horizontal_white.png"
            alt="footer logo"
            height={60}
            width={220}
          />
          <div className={Style.footer_box_down_explore}>
            {/*   <p className={Style.footer_box_down_link}>
              <Link href={{ pathname: "/docs/term_and_conditions" }}>
                {t("footer.terms")}
              </Link>
            </p>
            <p className={Style.footer_box_down_link}>
              <Link href={{ pathname: "/docs/privacy_policy" }}>
                {t("footer.privacy")}
              </Link>
            </p>*/}
          </div>
        </div>

        <div className={Style.footer_box_right}>
          <div className={Style.footer_social}>
            <a
              href="https://www.instagram.com/sefty_oficial"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/icons/InstagramWhite.svg"
                width={30}
                height={30}
                alt="instagram.com/sefty_oficial"
              />
            </a>
            <a
              href="https://twitter.com/sefty_oficial"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/icons/TwitterWhite.svg"
                width={30}
                height={30}
                alt="twitter.com/sefty_oficial"
              />
            </a>
          </div>

          <div className={Style.footer_box_down_info}>
            <p>{t("footer.copyRight")}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
