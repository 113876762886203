import { ContainerLanding } from "./styles";

export const DescriptionTwo = ({ text1, text2, text3, description }) => {
  return (
    <ContainerLanding>
      <div className="section-title center">
        <p className="text1">{text1},</p>
        <p className="text2">{text2}</p>
        <p className="text3">{text3}</p>
      </div>
      <div className="section-text">
        <p className="text-description">{description}</p>
      </div>
    </ContainerLanding>
  );
};
