import { device } from "../../../common/constants/device";
import styled, { keyframes } from "styled-components";

export const ContainerLanding = styled.section`
  max-width: 1300px;
  margin: auto;
  padding: 6rem 0;
  & > .section-title {
    max-width: 883px;
    margin: auto;
    &.center {
      & > .text1,
      & > .text3 {
        text-align: center;
      }
    }
    & > .text1,
    & > .text2,
    & > .text3 {
      color: var(--font-color-dark);
      font-family: Mulish;
      font-size: 100px;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 100px;
      & > .small {
        font-family: Nunito;
        font-size: 80px;
        font-weight: 400;
        text-transform: lowercase;
      }
    }
    & > .text2 {
      text-align: center;
    }
    & > .text3 {
      text-align: end;
      color: var(--Amethyst);
      & > .small {
        color: var(--font-color-dark);
      }
    }
  }
  & > .section-text {
    max-width: 700px;
    margin: 42px auto 1rem;
    & > .text-description {
      color: var(--6, #f2f2f2);
      text-align: center;
      font-family: Mulish;
      font-size: 20px;
      font-weight: 400;
      line-height: 34px; /* 170% */
    }
  }
  @media ${device.tabletL} {
    padding: 3rem 0;
  }
  @media ${device.tablet} {
    padding: 2rem;
    & > .section-title {
      & > .text1,
      & > .text2,
      & > .text3 {
        font-size: 60px;
        line-height: normal;
        & > .small {
          font-size: 50px;
        }
      }
    }
    & > .section-text {
      width: 90%;
      margin: 42px auto 1rem;
    }
  }
  @media ${device.mobileL} {
    & > .section-title {
      & > .text1,
      & > .text2,
      & > .text3 {
        font-size: 40px;
        line-height: normal;
        & > .small {
          font-size: 32px;
        }
      }
    }
    & > .section-text {
      width: 90%;
      margin: 42px auto 1rem;
      & > .text-description {
        font-size: 16px;
      }
    }
  }
`;
