import Style from "./home.module.css";
import { useTranslation } from "react-i18next";

import { HeroSection } from "./components/LandingPage/HeroSection";
import { Description } from "./components/LandingPage/Description";
import { DescriptionTwo } from "./components/LandingPage/Description/DescriptionTwo";
import { CarrouselModels } from "./components/LandingPage/CarrouselModels";
import Footer from "./components/Footer";
import NavBar from "./components/Navbar";

function App() {
  const { t } = useTranslation();
  return (
    <>
      <NavBar />
      <div className={Style.homePage}>
        <HeroSection />
        <Description />
        <CarrouselModels />
        <DescriptionTwo
          text1={t("landingPage.customize")}
          text2={t("landingPage.buy")}
          text3={t("landingPage.sell")}
          description={t("landingPage.description3")}
        />
        {/* <DescriptionTwo
          text1={t("landingPage.pleasure")}
          text2={t("landingPage.elevated")}
          text3={t("landingPage.art")}
          description={t("landingPage.description4")}
        /> */}
      </div>
      <Footer />
    </>
  );
}

export default App;
