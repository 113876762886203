export const translationsES = {
  heroVideoSection: {
    itle: "VIVE",
    itleSpan: "la",
    itleSpan2: "LUJURIA",
    itleSpan3: "en la",
    ansion: "mansión",
    efty: "Sefty",
    ystery: "misteriosa",
    ext: "Prepárate para descubrir un universo de placer, un lugar donde la emoción siempre te espera detrás de cada puerta.",
    ext2: "¿Está listo para, explorar el misterio que se esconde en cada rincón?",
    button: "Empezar",
  },
  landingPage: {
    welcome: "Bienvenidos",
    the: "a la",
    mansion: "mansión",
    of: "de ",
    lust: "lujuria",
    sefty: "Sefty",
    description:
      "Un lugar donde la diversión renace, donde tus emociones se desatan y donde puedes ser completamente tú mismo.",
    description2:
      "¿Estás listo para descubrir rincones llenos de placer, diversión y confianza? Prepárate para liberar tus emociones más intensas en espacios exclusivos, que solo tú controlas.",
    description3:
      "Construye tu propia mansión para el placer, en donde cada característica, accesorio y detalle será escogido por ti, creando así experiencias totalmente únicas y personalizadas, con contenido protegido que podrá ser tuyo y hasta podrás comercializar.",
    description4:
      "Adéntrate en un reino de placer donde cada nivel de membresía es una puerta a experiencias más intensas y exclusivas. Tu entrada a la jerarquía real te eleva a nuevas alturas de deleite, donde cada rincón revela secretos más sensuales y satisfacción absoluta. Únete a nosotros y descubre las llaves que desbloquearán un mundo donde el placer tiene la más alta jerarquía.",
    btnBuy: "Nos vemos pronto...",
    customize: "Personaliza",
    buyAnd: "compra &",
    buy: "Compra &",
    sell: "vende",
    pleasure: "El placer en",
    elevated: "jerarquía",
    art: "real",
    owner: "El propietario de este NFT recibirá:",
    text1: "1 mes de suscripción gratuita a mi página Sefty",
    text2:
      "Vídeo GRATIS de 2 minutos bailando en topless con una ropa similar.",
    text3: "2 Fotos con la ropa que más te guste.",
    explore: "Explora y descubre",
    exploreDescription:
      "Explora tus deseos más profundos y descubre lo que hemos preparado para ti en este lugar de exclusividad y deleite, donde las fantasías se convierten en realidad. \n\n\n Aquí la diversión nunca termina .",
    create: "Crea y personaliza",
    createDescription:
      "Personaliza cada rincón de tu experiencia, con la libertad de ser el director de tu propia historia. Aquí tu deseo es nuestra orden y cuentas con variedad de opciones y detalles sensuales para crear tu propio relato de pasión y misterio.",
    enjoy: "Disfruta y valora",
    enjoyDescription:
      "Aquí, cada momento es una oportunidad para apreciar la belleza del placer y la emoción. Valora la exclusividad que te ofrecemos y disfruta de una experiencia que es especialmente diseñada para ti.",
    sellAndWin: "Vende y gana con todo",
    sellAndWinDescription:
      "Te brindamos la oportunidad de explorar espacios donde tus deseos se convierten en moneda de cambio. Vender y ganar nunca fue tan sensual y emocionante. Aquí, podrás crear y ofrecer experiencias que despiertan pasiones y satisfacen deseos.",
    question: "¿Vamos por el segundo?",
    questionDescription:
      "No tenemos límites para la intensidad y el deseo, te invitamos a duplicar la pasión y la emoción en cada visita. Aquí, la diversión continúa con experiencias aún más provocativas, descubriendo un mundo de placer que se despliega ante ti.",
    tooltip0:
      "El usuario tendrá la libertad de navegar por todo el ecosistema sin ninguna restricción.",
    tooltip1:
      " Eventos exclusivos que puede disfrutar de forma remota y segura, con experiencias únicas y emocionantes que solo sefty ha preparado especialmente para su disfrute.  ",
    tooltip2: "Podrás enviar mensajes personalizados a modelos.",
    tooltip3: "Llamadas que podrás hacer a las modelos.",
    tooltip4:
      "El usuario disfrutará el detrás de bastidores de como la modelo vive el antes, durante y después de un show.",
    tooltip5:
      "Beneficio que se otorga al usuario al momento de adquirir accesorios para la creación de contenido.",
    tooltip6:
      "Contenidos exclusivos de las modelos más TOP . Estos contenidos están disponibles únicamente para aquellos que forman parte de nuestra comunidad.",
    tooltip7:
      "Al comprar la membresía podrás redimir el valor en coins y disfrutarlos navegando en el ecosistema Sefty.",
    twentyPerMonth: "20 por mes",
    twentyDiscount: "20% de descuento",
    btnPionner: "Haz click y se pionero",
  },
  footer: {
    terms: "Términos y Condiciones",
    privacy: "Política de Privacidad",
    copyRight: "© 2023 Sefty.io. Todos los derechos reservados",
  },
};
